<script>
import router from '../../index'
export default {
  created() {
    localStorage.removeItem("email");
    localStorage.removeItem("authenticatedUserId");
    localStorage.removeItem("userType");
    localStorage.removeItem("userEmail");
    console.log('email')
    router.push('/login');
  }
}
</script>